import iban from "./iban";
import domain from "./domain";
import hostname from "./hostname";
import serverHostname from "~/formkit/rules/serverHostname";
import hostname_ip from "./hostname_ip";

export const rules = {
  iban,
  domain,
  hostname,
  hostname_ip,
  serverHostname
}
