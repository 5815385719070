/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VirtualMachineTask_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read = {
    /**
     * The unique identifier of the task
     */
    readonly id?: string;
    /**
     * The action of the task
     */
    action?: VirtualMachineTask_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read.action;
    createdAt?: string;
    updatedAt?: string;
};

export namespace VirtualMachineTask_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read {

    /**
     * The action of the task
     */
    export enum action {
        START = 'START',
        STOP = 'STOP',
        RESTART = 'RESTART',
        SHUTDOWN = 'SHUTDOWN',
    }


}

