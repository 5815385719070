/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IpAddress = {
    readonly id?: string;
    customer?: string | null;
    ipAddress?: string;
    subnet?: string;
    /**
     * The gateway IP address
     */
    gateway?: string;
    netmask?: string;
    /**
     * The reverse DNS PTR record
     */
    ptrRecord?: string | null;
    type?: IpAddress.type;
    ipAddressPool?: string | null;
    createdAt?: string;
    updatedAt?: string;
};

export namespace IpAddress {

    export enum type {
        IPV4 = 'ipv4',
        IPV6 = 'ipv6',
    }


}

