/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Handle_CreateHandleRequest_handle_write = {
    firstname: string;
    lastname: string;
    company: string | null;
    address: string;
    city: string;
    zipcode: string;
    /**
     * ISO 3166-1 alpha-2 country code
     */
    countryCode: string;
    email: string;
    /**
     * The phone prefix, e.g. +49 for Germany
     */
    phone1: string;
    /**
     * The area code
     */
    phone2: string;
    /**
     * The phone number
     */
    phone3: string;
    /**
     * The fax prefix, e.g. +49 for Germany
     */
    fax1: string;
    /**
     * The area code
     */
    fax2: string;
    /**
     * The fax number
     */
    fax3: string;
    type: Handle_CreateHandleRequest_handle_write.type;
};

export namespace Handle_CreateHandleRequest_handle_write {

    export enum type {
        ORG = 'ORG',
        PERS = 'PERS',
        ROLE = 'ROLE',
    }


}

