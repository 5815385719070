/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Get a bill by id
 */
export type Bill = {
    id?: string;
    name?: string;
    /**
     * The day of the month when the quota is reset
     */
    billDay?: number;
    /**
     * The maximum speed of the traffic plan
     */
    allowed?: string;
    /**
     * The current used traffic of the traffic plan
     */
    used?: string;
    /**
     * The type of the billing
     */
    type?: Bill.type;
    rateAverageMbit?: string;
    rate95thMbit?: string;
    /**
     * The current used traffic of the traffic plan in percent
     */
    usedInPercent?: number;
};

export namespace Bill {

    /**
     * The type of the billing
     */
    export enum type {
        CDR = 'CDR',
        QUOTA = 'QUOTA',
    }


}

