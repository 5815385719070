/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiToken_api_token_read_timestampable_created_at_read_timestampable_updated_at_read } from '../models/ApiToken_api_token_read_timestampable_created_at_read_timestampable_updated_at_read';
import type { ApiToken_ApiTokenCreatedResponse } from '../models/ApiToken_ApiTokenCreatedResponse';
import type { ApiToken_CreateApiTokenRequest } from '../models/ApiToken_CreateApiTokenRequest';
import type { LoginActivity } from '../models/LoginActivity';
import type { User } from '../models/User';
import type { User_ChangePasswordRequest_Default } from '../models/User_ChangePasswordRequest_Default';
import type { User_CompleteUserRequest_user_complete } from '../models/User_CompleteUserRequest_user_complete';
import type { User_Customer } from '../models/User_Customer';
import type { User_RegisterUserRequest_user_register } from '../models/User_RegisterUserRequest_user_register';
import type { User_RequestPasswordRecoveryRequest_Default } from '../models/User_RequestPasswordRecoveryRequest_Default';
import type { User_SetDefaultCustomerRequest_Default } from '../models/User_SetDefaultCustomerRequest_Default';
import type { User_UserMeResponse_user_read_user_me_read_account_me_customer_me } from '../models/User_UserMeResponse_user_read_user_me_read_account_me_customer_me';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Test the password recovery token
     * Retrieves a User resource.
     * @returns any The password recovery token is valid or invalid
     * @throws ApiError
     */
    public static testPasswordRecovery({
        id,
    }: {
        /**
         * User identifier
         */
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/recover-password/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Recover the password
     * Creates a User resource.
     * @returns void
     * @throws ApiError
     */
    public static recoverPassword({
        id,
        requestBody,
    }: {
        /**
         * User identifier
         */
        id: string,
        /**
         * The new User resource
         */
        requestBody: User_ChangePasswordRequest_Default,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/recover-password/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The password recovery token is invalid or expired`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Register a new user
     * Creates a User resource.
     * @returns User User resource created
     * @throws ApiError
     */
    public static registerUser({
        requestBody,
    }: {
        /**
         * The new User resource
         */
        requestBody: User_RegisterUserRequest_user_register,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Request a password recovery
     * Creates a User resource.
     * @returns void
     * @throws ApiError
     */
    public static requestPasswordRecovery({
        requestBody,
    }: {
        /**
         * The new User resource
         */
        requestBody: User_RequestPasswordRecoveryRequest_Default,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/request-password-recovery',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves the collection of ApiToken resources.
     * Retrieves the collection of ApiToken resources.
     * @returns ApiToken_api_token_read_timestampable_created_at_read_timestampable_updated_at_read ApiToken collection
     * @throws ApiError
     */
    public static getApiTokens({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<ApiToken_api_token_read_timestampable_created_at_read_timestampable_updated_at_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/api-tokens',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Creates a ApiToken resource.
     * Creates a ApiToken resource.
     * @returns ApiToken_ApiTokenCreatedResponse ApiToken resource created
     * @throws ApiError
     */
    public static createApiToken({
        requestBody,
    }: {
        /**
         * The new ApiToken resource
         */
        requestBody: ApiToken_CreateApiTokenRequest,
    }): CancelablePromise<ApiToken_ApiTokenCreatedResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/api-tokens',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the ApiToken resource.
     * Removes the ApiToken resource.
     * @returns void
     * @throws ApiError
     */
    public static deleteApiToken({
        id,
    }: {
        /**
         * ApiToken identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/api-tokens/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Complete the user registration
     * Creates a User resource.
     * @returns User User resource created
     * @throws ApiError
     */
    public static completeUser({
        requestBody,
    }: {
        /**
         * The new User resource
         */
        requestBody: User_CompleteUserRequest_user_complete,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/complete',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Get the customers of the current user
     * Retrieves the collection of User resources.
     * @returns User_Customer User collection
     * @throws ApiError
     */
    public static getUserCustomers({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<User_Customer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/customers',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Set the default customer of the current user
     * Creates a User resource.
     * @returns void
     * @throws ApiError
     */
    public static setDefaultCustomer({
        requestBody,
    }: {
        /**
         * The new User resource
         */
        requestBody: User_SetDefaultCustomerRequest_Default,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/default-customer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves the collection of LoginActivity resources.
     * Retrieves the collection of LoginActivity resources.
     * @returns LoginActivity LoginActivity collection
     * @throws ApiError
     */
    public static getLoginActivities({
        page = 1,
        orderLoginAt,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        orderLoginAt?: 'asc' | 'desc',
    }): CancelablePromise<Array<LoginActivity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/login-activities',
            query: {
                'page': page,
                'order[loginAt]': orderLoginAt,
            },
        });
    }

    /**
     * Get the current user
     * Retrieves a User resource.
     * @returns User_UserMeResponse_user_read_user_me_read_account_me_customer_me User resource
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<User_UserMeResponse_user_read_user_me_read_account_me_customer_me> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/me',
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
