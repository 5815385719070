<script setup lang="ts">
import {onMounted} from "vue";
import type {PropType, ComputedRef} from "vue";
import type {FormKitContext, FormKitFrameworkContext} from "@formkit/core";
import {useDialog} from "primevue/usedialog";
import {useToast} from "primevue/usetoast";
import {useFetch} from "#app";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import {useApiDropdown} from "~/stores/api-dropdown";
import {useAuth} from "~/stores/auth";

const dialog = useDialog()
const toast = useToast()
const apiRootUrl = useRuntimeConfig().public.apiRootUrl

const props = defineProps({
  context: Object as PropType<FormKitContext>,
  apiEndpoint: {
    type: String,
    required: false
  },
  apiTransformFunction: {
    type: Function as PropType<(data: any, { lang }: { lang: (t: string) => string }) => any>,
    required: false
  },
  defaultPlaceholder: {
    type: String,
    required: false
  },
  headerComponent: {
    type: Object,
    required: false
  },
  headerComponentProps: {
    type: Object,
    required: false
  },
  type: {
    type: String as PropType<'single' | 'multi'>,
    required: false,
    default: 'multi'
  },
  preloadOptions: {
    type: Boolean,
    required: false,
    default: false
  },
  defaultOption: {
    type: Object,
    required: false,
    default: undefined
  },
  headerComponentPermissions: {
    type: Array as PropType<string[]>,
    required: false,
    default: undefined
  },
})

const emit = defineEmits(['onRawInput'])

const context: FormKitFrameworkContext = props.context
const attrs: Record<string, any> = context?.attrs

const apiEndpoint: ComputedRef<string> = computed(() => attrs.apiEndpoint ?? props.apiEndpoint ?? false)
const apiTransformFunction: (response: any) => any = attrs.apiTransformFunction ?? props.apiTransformFunction ?? false
const defaultPlaceholder: string = attrs.defaultPlaceholder ?? props.defaultPlaceholder ?? 'Bitte auswählen'
const headerComponent: any = attrs.headerComponent ?? props.headerComponent ?? false
const headerComponentProps: any = attrs.headerComponentProps ?? props.headerComponentProps ?? {}
const preselectOption: boolean = attrs.preselectOption ?? false
const defaultField: string = attrs.defaultField ?? 'default'
const preloadOptions: boolean = attrs.preloadOptions ?? props.preloadOptions ?? false
const defaultOption: any = attrs.defaultOption ?? props.defaultOption ?? undefined

const showHeader = computed(() => {
  if (!headerComponent) {
    return false
  }

  return true
})

if (!apiEndpoint.value) {
  throw new Error('apiEndpoint is required')
}

if (!apiTransformFunction) {
  throw new Error('apiTransformFunction is required')
}

function handleBlur(e: any) {
  context?.handlers.blur(e.value)
}
function handleInput(e: any) {
  const rawValue = attrs?.options.find((option: any) => option.value === e.value)
  emit('onRawInput', rawValue)
  context?.node.input(e.value)
}

const addContextInputValues = () => {
  if (attrs.optionsAdded) {
    return
  }
  // add context input values

  const isCurrentInputValueObjectOrArray = typeof context?.node._value === 'object';
  if (isCurrentInputValueObjectOrArray) {
    const defaultValue = props.type === 'multi' ? [] : ''
    const currentOptions = attrs.options ?? []
    const currentValueOptionsOrOption = context?.node._value ?? defaultValue

    if (props.type === 'multi') {
      // selected options, e.g. [{id: 1, name: 'foo'}, {id: 2, name: 'bar'}] should be added to the options
      // so that the user can see the selected options in the dropdown before the api call is executed
      const currentValueOptions = currentValueOptionsOrOption ?? []

      // check if currentValueOptions are only a string or an object
      const isCurrentValueOptionsOnlyStrings = currentValueOptions.every((value: any) => {
        return typeof value === 'string'
      })

      if (isCurrentValueOptionsOnlyStrings) {
        // if currentValueOptions are only strings, we don't need to add them to the options, they should already be there
        return
      }

      attrs.options = [...currentOptions, ...currentValueOptions.map((value: any) => {
        return apiTransformFunction(value, { lang: (t: string) => t })
      })]

      // add defaultOption on first position
      if (defaultOption) {
        attrs.options.unshift(defaultOption)
      }

      const currentInputValue = currentValueOptions.map((value: any) => value.id);
      if (currentInputValue.length > 0) {
        context?.node.input(currentInputValue)
      } else if (preselectOption) {
        if (attrs.options.length > 0) {
          // find by defaultField
          const defaultOption = attrs.options.find((option: any) => {
            return option[defaultField] === true
          }) ?? attrs.options[0] ?? null

          context?.node.input(defaultOption?.value)
        }
      }
      attrs.optionsAdded = true
    } else {
      const currentValueOption = currentValueOptionsOrOption ?? ''

      if (typeof currentValueOption === 'string') {
        // if currentValueOption is only a string, we don't need to add it to the options, it should already be there
        return
      }

      attrs.options = [...currentOptions, apiTransformFunction(currentValueOption, { lang: (t: string) => t })]

      // add defaultOption on first position
      if (defaultOption) {
        attrs.options.unshift(defaultOption)
      }

      if (currentValueOption?.id) {
        context?.node.input(currentValueOption.id)
      } else if (preselectOption) {
        if (attrs.options.length > 0) {
          // find by defaultField
          const defaultOption = attrs.options.find((option: any) => {
            return option[defaultField] === true
          }) ?? attrs.options[0] ?? null

          context?.node.input(defaultOption?.value)
        }
      }

      attrs.optionsAdded = true
    }
  }
}

const convertValueToIdOrIds = () => {
  if (props.type === 'multi') {
    const currentValue = context?.node._value ?? []
    if (!Array.isArray(currentValue)) {
      throw new Error('[ApiDropdown] currentValue must be an array when type is multi (1)')
    }

    // check if currentValue is only a string or an object
    const isCurrentValueOnlyStrings = currentValue.every((value: any) => {
      return typeof value === 'string'
    })
    if (isCurrentValueOnlyStrings) {
      // if currentValue is only strings, we don't need to convert it to ids
      return
    }

    const currentValueIds = currentValue.map((value: any) => value.id)
    context?.node.input(currentValueIds)
  } else {
    const currentValue = context?.node._value ?? ''

    // check if currentValue is a string or an object
    const isCurrentValueOnlyString = typeof currentValue === 'string'
    if (isCurrentValueOnlyString) {
      // if currentValue is only a string, we don't need to convert it to an id
      return
    }

    // check if current value is a real object (not null, etc)
    if (!currentValue) {
      return
    }

    const currentValueId = currentValue.id
    context?.node.input(currentValueId)
  }
}

watch(props.context, (value, oldValue, onCleanup) => {
  addContextInputValues()
  convertValueToIdOrIds()
})

const showCreate = ref(false);

const apiEndpointFull = computed(() => {
  return apiRootUrl + apiEndpoint.value
});

const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid) ? `${attrs?.class ?? ''} p-invalid` : attrs?.class ?? '')
const { pending, execute, status, refresh } = useFetch(apiEndpointFull, {
  immediate: false,
  headers: {
    'Authorization': 'Bearer ' + useAuth().getAccessToken,
  },
  onResponse({ response }): Promise<void> | void {
    const existingItems: {
      id: string
      name: string
    }[] = attrs.options ?? []

    const dataValues = response._data?.data ? response._data.data : response._data

    const dataItems = dataValues.map((dataItem: any) => {
      return apiTransformFunction(dataItem, { lang: (t: string) => t })
    })

    dataItems.push(...existingItems.map((dataItem: any) => {
      return apiTransformFunction(dataItem, { lang: (t: string) => t })
    }))

    // add defaultOption on first position
    if (defaultOption) {
      dataItems.unshift(defaultOption)
    }

    // clear duplicates
    const uniqueItems = dataItems.filter((dataItem, index, self) =>
      index === self.findIndex((t) => (
        t.value === dataItem.value
      ))
    )

    attrs.options = uniqueItems

    uniqueItems.forEach(item => useApiDropdown().addItem(item))

    if (isExecutedInit && preselectOption) {
      if (attrs.options.length > 0) {
        // find by defaultField
        const defaultOption = attrs.options.find((option: any) => {
          return option[defaultField] === true
        }) ?? attrs.options[0] ?? null

        context?.node.input(defaultOption?.value)
      }
    }
  }
})

const isExecutedInit = ref(false)

const onBeforeShow = async () => {
  if (status.value === 'pending') {
    await execute()
  } else {
    await refresh()
  }

  isExecutedInit.value = true
}

onMounted(() => {
  addContextInputValues()

  if (preloadOptions) {
    execute()
  }
})

const placeholder = computed(() => {
  return attrs?.placeholder ?? defaultPlaceholder
})

const isLoading = computed(() => {
  if (!isExecutedInit.value) {
    return false
  }
  return pending.value
})

const setInput = (value: any) => {
  if (props.type === 'multi' && !Array.isArray(value)) {
    throw new Error('[ApiDropdown] setInputValue must be called with an array when type is multi')
  } else if (props.type === 'single' && Array.isArray(value)) {
    throw new Error('[ApiDropdown] setInputValue must be called with a string when type is single')
  }

  context?.node.input(value)
}

context?.node.on('prop', async ({ payload }) => {
  // refresh options if prop (which is used in apiEndpoint) has changed
  if (!context?.node.props.definition?.props?.includes(payload.prop)) {
    return
  }

  attrs.options = []
  await execute()
})
</script>

<template>
  <div>
    <component
      :is="type === 'multi' ? MultiSelect : Dropdown"
      :loading="isLoading"
      v-model="context._value"
      :input-id="context.id"
      :disabled="attrs._disabled ?? false"
      :readonly="attrs._readonly ?? false"
      :input-class="styleClass"
      :style-class="attrs.styleClass"
      :tabindex="attrs.tabindex"
      :aria-label="attrs.ariaLabel"
      :aria-labelledby="attrs.ariaLabelledby"
      :options="attrs.options"
      :option-label="attrs.optionLabel ?? 'label'"
      :option-value="attrs.optionValue ?? 'value'"
      :placeholder="placeholder"
      :filter="attrs.filter ?? false"
      :show-clear="attrs.showClear ?? false"
      :pt="attrs.pt"
      :pt-options="attrs.ptOptions"
      :unstyled="attrs.unstyled ?? false"
      @change="handleInput"
      @blur="handleBlur"
      @hide="showCreate = false"
      @before-show="onBeforeShow"
    >
      <template #header>
        <component
          v-if="headerComponent && showHeader"
          :is="headerComponent"
          v-bind="headerComponentProps"
          :refresh="refresh"
          :set-input-value="setInput"
          v-model="showCreate"
        />
      </template>
      <template #option="slotProps" v-if="!!$slots.option">
        <slot name="option" v-bind="slotProps" />
      </template>
      <template #value="slotProps" v-if="!!$slots.value">
        <slot name="value" v-bind="slotProps" />
      </template>
    </component>
  </div>
</template>
