/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Colocation_Interconnection = {
    readonly id?: string;
    name?: string;
    readonly note?: string | null;
    /**
     * The speed of the interconnection in Mbps.
     */
    speed?: number;
    /**
     * The type of the interconnection.
     */
    type?: Colocation_Interconnection.type;
    /**
     * The status of the interconnection.
     */
    status?: Colocation_Interconnection.status;
    /**
     * The type of the cable used for the interconnection.
     */
    cableType?: Colocation_Interconnection.cableType;
    colocation?: string;
    createdAt?: string;
    updatedAt?: string;
    readonly customer?: string | null;
};

export namespace Colocation_Interconnection {

    /**
     * The type of the interconnection.
     */
    export enum type {
        CROSS_CONNECT = 'cross_connect',
        IP_TRANSIT = 'ip_transit',
        PRIVATE_PEERING = 'private_peering',
        PUBLIC_PEERING = 'public_peering',
        VIRTUAL_CROSS_CONNECT = 'virtual_cross_connect',
        VIRTUAL_PRIVATE_LAN_SERVICE = 'virtual_private_lan_service',
        VIRTUAL_ROUTING_AND_FORWARDING = 'virtual_routing_and_forwarding',
        VIRTUAL_WAN_SERVICE = 'virtual_wan_service',
    }

    /**
     * The status of the interconnection.
     */
    export enum status {
        ACTIVE = 'active',
        INACTIVE = 'inactive',
    }

    /**
     * The type of the cable used for the interconnection.
     */
    export enum cableType {
        SHARED = 'shared',
        DEDICATED = 'dedicated',
    }


}

