/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IpAddress_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read } from './IpAddress_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read';
import type { Region_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read } from './Region_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read';

export type VirtualMachine_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read = {
    readonly id?: string;
    readonly status?: VirtualMachine_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read.status;
    name?: string;
    cpu?: number;
    ramMb?: number;
    diskMb?: number;
    ipV4?: IpAddress_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read | null;
    ipV6?: IpAddress_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read | null;
    architecture?: VirtualMachine_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read.architecture;
    /**
     * UUID of the virtual machine
     */
    readonly vmUuid?: string | null;
    createdAt?: string;
    updatedAt?: string;
    /**
     * Availability zone of the cloud server
     */
    readonly region?: Region_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read;
};

export namespace VirtualMachine_cloud_server_list_region_read_timestampable_created_at_read_timestampable_updated_at_read {

    export enum status {
        PENDING = 'pending',
        STARTING = 'starting',
        CREATING = 'creating',
        RUNNING = 'running',
        REBOOTING = 'rebooting',
        STOPPING = 'stopping',
        STOPPED = 'stopped',
        DELETING = 'deleting',
        SHUTTING_DOWN = 'shutting-down',
        DELETED = 'deleted',
    }

    export enum architecture {
        X86_64 = 'x86_64',
        AARCH64 = 'aarch64',
    }


}

