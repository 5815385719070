import {createMessage, FormKitNode} from "@formkit/core";
import {OpenAPI} from "~/api-generated/core/OpenAPI";
import {useAuth} from "~/stores/auth";

/**
 * A contrived validation rule that checks if the value is a valid iban.
 */
const domain = async function (node: FormKitNode) {
  node.store.remove('rule_domain_invalid')
  node.store.remove('rule_domain_valid')
  node.store.remove('rule_domain_api_error')
  node.store.remove('rule_domain_required')
  node.context.iconRight = ''

  if ((!node.value) && node.props.validationRules?.required) {
    return false;
  }

  if (!node.value) {
    return true;
  }

  node.props.validationVisibility = 'live'

  const validatingDomainMessage = createMessage({
    key: 'rule_domain_validating',
    value: 'Die Verfügbarkeit wird überprüft...',
    visible: true,
    blocking: true,
    type: 'loading'
  })

  node.store.set(validatingDomainMessage)

  node.context.iconRight = 'pi pi-spin pi-spinner'

  const authCodeField = node.props?.attrs?.authCodeField || false;
  const authCodeNode = node.parent?.find(authCodeField)

  const currentHelpText = node.props.help || ''

  const toggleFieldVisibility = (isHidden: boolean, emptyAuthCode?: boolean = false) => {
    node.props.disabled = isHidden
    if (authCodeNode) {
      authCodeNode.props.disabled = isHidden
      if (emptyAuthCode) {
        authCodeNode.input('')
      }
    }
  }

  // set all fields disabled until iban is validated
  toggleFieldVisibility(true)

  const data = ref(null)
  const status = ref('idle')

  try {
    data.value = await $fetch(OpenAPI.BASE + '/domains/check-availability', {
      method: 'POST',
      body: JSON.stringify({
        name: node.value
      }),
      headers: {
        'Authorization': `Bearer ${useAuth().getAccessToken}`
      },
    })
    status.value = 'success'
  } catch (e) {
    status.value = 'error'
    data.value = null
  }

  node.store.remove('rule_domain_validating')

  node.props.help = currentHelpText

  toggleFieldVisibility(false)

  node.context.iconRight = ''

  if (status.value === 'error') {
    node.context.iconRight = 'pi pi-exclamation-triangle p-invalid-icon'
    const apiErrorMessage = createMessage({
      key: 'rule_domain_api_error',
      value: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      visible: true,
      blocking: true,
      type: 'validation'
    })

    node.store.set(apiErrorMessage)

    return;
  } else if (!data.value?.available) {
    node.context.iconRight = 'pi pi-times p-invalid-icon'
    toggleFieldVisibility(false, true)

    const invalidMessage = createMessage({
      key: 'rule_domain_invalid',
      value: 'Die Domain ist bereits vergeben. Für eine Übernahme geben Sie bitte den Auth-Code ein.',
      visible: true,
      blocking: false,
      type: 'validation'
    })

    node.store.set(invalidMessage)

    node.context?.attrs?.onDomainValidated?.(data.value)
  } else {
    node.context.iconRight = 'pi pi-check p-valid-icon'

    const validMessage = createMessage({
      key: 'rule_domain_valid',
      value: 'Die Domain ist verfügbar.',
      visible: true,
      blocking: false,
      type: 'success'
    })

    node.store.set(validMessage)

    node.context?.attrs?.onDomainValidated?.(data.value)
  }

  return true;
}

domain.blocking = true
domain.skipEmpty = false
domain.debounce = 300 // milliseconds
domain.force = true

export default domain
