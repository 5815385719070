import mitt from 'mitt'

type Events = {
  'dns_record.written': void
  'dns_record.refresh': void

  'handle.refresh': void
  'handle.written': void

  'domain.written': void
  'ticket.written': void

  'email_protection.written': void

  'api_token.written': void
}

const emitter = mitt<Events>()

export const useEmitter = () => {
  const on = emitter.on
  const off = emitter.off
  const emit = emitter.emit
  const clear = emitter.all.clear

  return { on, off, emit, clear }
}
