import { useAuth } from "~/stores/auth";

export default defineNuxtRouteMiddleware((to) => {
    const authStore = useAuth()
    const unprotectedRoutes = ['/auth/login', '/auth/register', '/auth/forgot-password', '/auth/recover-password']
    if (unprotectedRoutes.includes(to.path) && authStore.isAuthenticated) {
        if (authStore.getRedirect) {
            authStore.redirectOnLogin(null)
            return navigateTo(authStore.getRedirect)
        } else {
            return navigateTo('/')
        }
    }

    if (to.meta.auth === false) {
        return
    }

    if (!authStore.isAuthenticated && !to.path.startsWith('/auth')) {
        authStore.redirectOnLogin(to.path)
        return navigateTo('/auth/login')
    }
})
