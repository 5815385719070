<script lang="ts" setup>
import {PropType, ref} from 'vue'
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from '@headlessui/vue'
import {FormKitContext, FormKitFrameworkContext} from "@formkit/core";


const props = defineProps({
  context: Object as PropType<FormKitContext>,
})

const context: FormKitFrameworkContext = props.context
const attrs: Record<string, any> = context?.attrs ?? {}

const label = ref(attrs.label ?? '')
const options = ref(attrs.options ?? [])
const optionTemplate = attrs.optionTemplate ?? attrs['option-template'] ?? null
const columns = attrs.columns ?? 1


const optionLabel = (option: any) => option.label ?? option
const optionValue = (option: any) => option.value ?? option
const optionDescription = (option: any) => option.description ?? option

const handleInput = (e: any) => {
  context?.node.input(e)
}

const handleBlur = (e: any) => {
  context?.handlers.blur(e)
}

</script>
<template>
  <div class="w-full">
    <div class="w-full">
      <RadioGroup
          v-model="context._value"
          :id="context.id"
          :disabled="attrs._disabled ?? false"
          :readonly="attrs._readonly ?? false"
          :name="attrs.name ?? context.id"
          @update:model-value="handleInput"
          @input="handleInput"
          @blur="handleBlur"
          :aria-label="attrs.ariaLabel"
          :aria-labelledby="attrs.ariaLabelledby"
      >
        <RadioGroupLabel class="sr-only">{{ label }}</RadioGroupLabel>
        <div class="w-full md:grid gap-3" :class="'md:grid-cols-' + columns">
          <RadioGroupOption
              as="template"
              v-for="(option, index) in options"
              :key="index"
              :value="optionValue(option)"
              v-slot="{ active, checked, disabled }"
              :disabled="option.disabled ?? false"
          >
            <div
                :class="[
                active
                  ? 'border-netzfabrik-indigo'
                  : '',
                checked ? 'border-netzfabrik-indigo border-2 bg-gray-50' : 'bg-white border-gray-200',
                disabled ? 'opacity-60 cursor-not-allowed' : '',
              ]"
                class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-sm focus:outline-none border hover:bg-gray-50"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center w-full">
                  <div class="text-sm w-full">
                    <component v-if="optionTemplate" :is="optionTemplate" v-bind="{option, active, checked}" />
                    <div v-else>
                      <slot name="option" :option="option" :active="active" :checked="checked">

                        <RadioGroupLabel
                            as="p"
                            :class="checked ? 'text-black' : 'text-gray-900'"
                            class="font-medium"
                        >
                          {{ optionLabel(option) }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                            as="span"
                            :class="checked ? 'text-gray-500' : 'text-gray-500'"
                            class="inline"
                        >
                          <slot name="description" :option="option">
                            {{ optionDescription(option) }}
                          </slot>
                        </RadioGroupDescription>

                      </slot>
                    </div>
                  </div>
                </div>
                <div v-show="checked" class="shrink-0 text-netzfabrik-indigo" v-if="false">
                  <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                    <circle
                        cx="12"
                        cy="12"
                        r="12"
                        fill="#a111fa"
                        fill-opacity="0.2"
                    />
                    <path
                        d="M7 13l3 3 7-7"
                        stroke="#a111fa"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
  </div>
</template>