/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VLAN = {
    readonly id?: string;
    name?: string;
    /**
     * The VLAN tag.
     */
    tag?: number;
    readonly ipamId?: number | null;
    /**
     * The type of the VLAN.
     */
    type?: VLAN.type;
    /**
     * The layer of the VLAN.
     */
    layer?: VLAN.layer;
    createdAt?: string;
    updatedAt?: string;
    customer?: string | null;
};

export namespace VLAN {

    /**
     * The type of the VLAN.
     */
    export enum type {
        PUBLIC = 'PUBLIC',
        PRIVATE = 'PRIVATE',
    }

    /**
     * The layer of the VLAN.
     */
    export enum layer {
        LAYER2 = 'LAYER2',
        LAYER3 = 'LAYER3',
    }


}

