/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DomainReseller_DomainResellerBillingDataResponse = {
    /**
     * Name of the reseller, who produced the costs
     */
    reseller?: string;
    /**
     * Path to the reseller
     */
    path?: string;
    action?: DomainReseller_DomainResellerBillingDataResponse.action;
    /**
     * Domain who produced the costs
     */
    domain?: string | null;
    /**
     * Date of action execution
     */
    fromDate?: string | null;
    /**
     * Date until the costs
     */
    toDate?: string | null;
    lifetime?: DomainReseller_DomainResellerBillingDataResponse.lifetime | null;
    price?: number;
    currency?: string;
};

export namespace DomainReseller_DomainResellerBillingDataResponse {

    export enum action {
        ANNUALLY = 'ANNUALLY',
        TRANSFER = 'TRANSFER',
    }

    export enum lifetime {
        _1_YEAR = '1 YEAR',
    }


}

