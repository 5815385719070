/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Domain_CancelRequest } from '../models/Domain_CancelRequest';
import type { Domain_CheckDomainAvailabilityRequest_domain_check } from '../models/Domain_CheckDomainAvailabilityRequest_domain_check';
import type { Domain_domain_list } from '../models/Domain_domain_list';
import type { Domain_domain_read_handle_read_nameserver_read } from '../models/Domain_domain_read_handle_read_nameserver_read';
import type { Domain_DomainAvailabilityResponse } from '../models/Domain_DomainAvailabilityResponse';
import type { Domain_RegisterDomainRequest } from '../models/Domain_RegisterDomainRequest';
import type { Domain_TransitRequest } from '../models/Domain_TransitRequest';
import type { Domain_UpdateDomainNameserverRequest } from '../models/Domain_UpdateDomainNameserverRequest';
import type { Handle } from '../models/Handle';
import type { Handle_CreateHandleRequest_handle_write } from '../models/Handle_CreateHandleRequest_handle_write';
import type { Handle_handle_read } from '../models/Handle_handle_read';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DomainService {

    /**
     * Get the current domains
     * Retrieves the collection of Domain resources.
     * @returns Domain_domain_list Domain collection
     * @throws ApiError
     */
    public static getDomains({
        page = 1,
        name,
        tld,
        status,
        orderName,
        orderTld,
        orderStatus,
        orderRegistrationDate,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        name?: string,
        tld?: string,
        status?: string,
        orderName?: 'asc' | 'desc',
        orderTld?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderRegistrationDate?: 'asc' | 'desc',
    }): CancelablePromise<Array<Domain_domain_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/domains',
            query: {
                'page': page,
                'name': name,
                'tld': tld,
                'status': status,
                'order[name]': orderName,
                'order[tld]': orderTld,
                'order[status]': orderStatus,
                'order[registrationDate]': orderRegistrationDate,
            },
        });
    }

    /**
     * Register a new domain
     * Creates a Domain resource.
     * @returns any The domain registration is started and will be processed in the background
     * @throws ApiError
     */
    public static postDomain({
        requestBody,
    }: {
        /**
         * The new Domain resource
         */
        requestBody: Domain_RegisterDomainRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/domains',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Check if a domain is available
     * Creates a Domain resource.
     * @returns Domain_DomainAvailabilityResponse Domain resource created
     * @throws ApiError
     */
    public static checkDomainAvailability({
        requestBody,
    }: {
        /**
         * The new Domain resource
         */
        requestBody: Domain_CheckDomainAvailabilityRequest_domain_check,
    }): CancelablePromise<Domain_DomainAvailabilityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/domains/check-availability',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Get a domain by id
     * Retrieves a Domain resource.
     * @returns Domain_domain_read_handle_read_nameserver_read Domain resource
     * @throws ApiError
     */
    public static getDomain({
        id,
    }: {
        /**
         * Domain identifier
         */
        id: string,
    }): CancelablePromise<Domain_domain_read_handle_read_nameserver_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/domains/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Request the auth code for a domain
     * Creates a Domain resource.
     * @returns Domain_domain_read_handle_read_nameserver_read Domain resource created
     * @throws ApiError
     */
    public static retrieveDomainAuthCode({
        id,
        requestBody,
    }: {
        /**
         * Domain identifier
         */
        id: string,
        /**
         * The new Domain resource
         */
        requestBody: any,
    }): CancelablePromise<Domain_domain_read_handle_read_nameserver_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/domains/{id}/auth-code',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Request the auth code for a domain
     * Removes the Domain resource.
     * @returns void
     * @throws ApiError
     */
    public static deleteDomainAuthCode({
        id,
    }: {
        /**
         * Domain identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/domains/{id}/auth-code',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Cancel a domain on the given date. If no date is given, the domain will be cancelled immediately.
     * Creates a Domain resource.
     * @returns Domain_domain_read_handle_read_nameserver_read Domain resource created
     * @throws ApiError
     */
    public static cancelDomain({
        id,
        requestBody,
    }: {
        /**
         * Domain identifier
         */
        id: string,
        /**
         * The new Domain resource
         */
        requestBody: Domain_CancelRequest,
    }): CancelablePromise<Domain_domain_read_handle_read_nameserver_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/domains/{id}/cancel',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                405: `The domain is not online and cannot be cancelled.`,
                409: `The domain is already cancelled.`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Revokes the cancellation of a domain.
     * Removes the Domain resource.
     * @returns void
     * @throws ApiError
     */
    public static revokeCancelDomain({
        id,
    }: {
        /**
         * Domain identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/domains/{id}/cancel',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
                405: `The domain is not online and cannot be revoked.`,
                409: `The domain is not cancelled. You can only revoke a cancelled domain.`,
            },
        });
    }

    /**
     * Update nameservers
     * Replaces the Domain resource.
     * @returns Domain_domain_read_handle_read_nameserver_read Domain resource updated
     * @throws ApiError
     */
    public static updateDomainNameservers({
        id,
        requestBody,
    }: {
        /**
         * Domain identifier
         */
        id: string,
        /**
         * The updated Domain resource
         */
        requestBody: Domain_UpdateDomainNameserverRequest,
    }): CancelablePromise<Domain_domain_read_handle_read_nameserver_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/domains/{id}/nameservers',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Set the transfer lock for a domain. This is not available for .de domains.
     * Creates a Domain resource.
     * @returns Domain_domain_read_handle_read_nameserver_read Domain resource created
     * @throws ApiError
     */
    public static setDomainTransferLock({
        id,
        requestBody,
    }: {
        /**
         * Domain identifier
         */
        id: string,
        /**
         * The new Domain resource
         */
        requestBody: any,
    }): CancelablePromise<Domain_domain_read_handle_read_nameserver_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/domains/{id}/transfer-lock',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                405: `The transfer lock is not available for this domain.`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Delete the transfer lock for a domain. This is not available for .de domains.
     * Removes the Domain resource.
     * @returns void
     * @throws ApiError
     */
    public static deleteDomainTransferLock({
        id,
    }: {
        /**
         * Domain identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/domains/{id}/transfer-lock',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
                405: `The transfer lock is not available for this domain.`,
            },
        });
    }

    /**
     * Gives the domain to the registrar. This is only available for .de domains.
     * Creates a Domain resource.
     * @returns Domain_domain_read_handle_read_nameserver_read Domain resource created
     * @throws ApiError
     */
    public static transitDomain({
        id,
        requestBody,
    }: {
        /**
         * Domain identifier
         */
        id: string,
        /**
         * The new Domain resource
         */
        requestBody: Domain_TransitRequest,
    }): CancelablePromise<Domain_domain_read_handle_read_nameserver_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/domains/{id}/transit',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                405: `The transit is only available for .de domains.`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Get the current handles
     * Retrieves the collection of Handle resources.
     * @returns Handle Handle collection
     * @throws ApiError
     */
    public static getHandles({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<Handle>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/handles',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Create a new handle
     * Creates a Handle resource.
     * @returns Handle_handle_read Handle resource created
     * @throws ApiError
     */
    public static createHandle({
        requestBody,
    }: {
        /**
         * The new Handle resource
         */
        requestBody: Handle_CreateHandleRequest_handle_write,
    }): CancelablePromise<Handle_handle_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/handles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Get a handle by id
     * Retrieves a Handle resource.
     * @returns Handle Handle resource
     * @throws ApiError
     */
    public static getHandle({
        id,
    }: {
        /**
         * Handle identifier
         */
        id: string,
    }): CancelablePromise<Handle> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/handles/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
