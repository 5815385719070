import revive_payload_client_4sVQNw7RlN from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/netzfabrik/apanel/backbone-api/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/builds/netzfabrik/apanel/backbone-api/app/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import sentry_client_shVUlIjFLk from "/builds/netzfabrik/apanel/backbone-api/app/plugins/sentry.client.ts";
import openapi_a9BoO3U1Xj from "/builds/netzfabrik/apanel/backbone-api/app/plugins/openapi.ts";
import notiwind_qYGIDwLcpw from "/builds/netzfabrik/apanel/backbone-api/app/plugins/notiwind.ts";
import persistedstate_JtmcDzPHGu from "/builds/netzfabrik/apanel/backbone-api/app/plugins/persistedstate.js";
import auth_vT9JWWT9pN from "/builds/netzfabrik/apanel/backbone-api/app/plugins/auth.ts";
import tooltipCopy_f8o79sKwsh from "/builds/netzfabrik/apanel/backbone-api/app/plugins/tooltipCopy.ts";
import vue_dateformat_ILWVNWIu5M from "/builds/netzfabrik/apanel/backbone-api/app/plugins/vue-dateformat.ts";
import primevue_7rYYRZQLyx from "/builds/netzfabrik/apanel/backbone-api/app/plugins/primevue.ts";
import posthog_client_o9IEgU9LwG from "/builds/netzfabrik/apanel/backbone-api/app/plugins/posthog.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  sentry_client_shVUlIjFLk,
  openapi_a9BoO3U1Xj,
  notiwind_qYGIDwLcpw,
  persistedstate_JtmcDzPHGu,
  auth_vT9JWWT9pN,
  tooltipCopy_f8o79sKwsh,
  vue_dateformat_ILWVNWIu5M,
  primevue_7rYYRZQLyx,
  posthog_client_o9IEgU9LwG
]