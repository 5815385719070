/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Message_ticket_read_maintenance_read } from './Message_ticket_read_maintenance_read';

export type Ticket_ticket_read_maintenance_read = {
    readonly id?: string;
    readonly subject?: string;
    readonly ticketNumber?: string;
    readonly group?: Ticket_ticket_read_maintenance_read.group;
    readonly priority?: Ticket_ticket_read_maintenance_read.priority;
    readonly status?: Ticket_ticket_read_maintenance_read.status;
    readonly tags?: Ticket_ticket_read_maintenance_read.tags;
    messages?: Array<Message_ticket_read_maintenance_read>;
};

export namespace Ticket_ticket_read_maintenance_read {

    export enum group {
        NETZFABRIK_SUPPORT = 'NETZFABRIK Support',
        NETZFABRIK_SALES = 'NETZFABRIK Sales',
        NETZFABRIK_BILLING = 'NETZFABRIK Billing',
        NETZFABRIK_GENERAL_REQUESTS = 'NETZFABRIK General Requests',
    }

    export enum priority {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }

    export enum status {
        '_4' = 4,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_7' = 7,
    }

    export enum tags {
        SERVER_PROBLEMS = 'Server Problems',
        NETWORK_PROBLEMS = 'Network Problems',
        OTHER_PROBLEMS = 'Other Problems',
        VAT = 'VAT',
        PAYMENT = 'Payment',
    }


}

