/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DomainReseller_domain_reseller_read = {
    readonly id?: string;
    /**
     * Username for the domain reseller interface
     */
    username?: string;
    /**
     * Password for the domain reseller interface
     */
    password?: string;
    /**
     * URL to the domain reseller interface
     */
    url?: string;
    type?: DomainReseller_domain_reseller_read.type;
};

export namespace DomainReseller_domain_reseller_read {

    export enum type {
        GRAY_LABEL = 'gray_label',
        WHITE_LABEL = 'white_label',
        NONE = 'none',
    }


}

