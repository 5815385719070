import {copyToClipboard} from "~/utils/clipboard";
import {defineNuxtPlugin} from "#app";

const tooltipDirective = (app: any) => {
  app.directive('copy', {
    mounted(el: any, binding: any) {
      init(el, binding, false)
    },
    updated(el: any, binding: any) {
      init(el, binding, false)
    },
    unmounted(el: any) {
      // check if $destroyCopy function exists on el
      if (el?.$destroyCopy) {
        el.$destroyCopy()
      }
    },
  })


  app.directive('copyid', {
    mounted(el: any, binding: any) {
      init(el, binding, true)
    },
    updated(el: any, binding: any) {
      init(el, binding, true)
    },
    unmounted(el: any) {
      el.$destroyCopy()
    },
  })
}

function init(el: any, binding: any, copyId: boolean = false) {
  const position = binding.arg || 'right'
  const copyText = binding.value || ''

  if (copyText.length === 0) {
    return
  }
  el.setAttribute('copy-tooltip-position', position)
  el.setAttribute('copy-text', copyText)

  el.setAttribute('copy-tooltip', !copyId ? 'Kopieren' : 'ID kopieren')
  el.setAttribute('copy-tooltip-copied', false)
  el.classList.add('data-table__no-data-row')

  el.$copyValue = copyText
  const handler = (event) => {
    if (el.$copyValue.length > 0) {
      event.stopPropagation()
      event.preventDefault()
    }

    copyToClipboard(el.$copyValue)
    el.setAttribute('copy-tooltip', !copyId ? 'Kopiert' : 'ID kopiert')
    el.setAttribute('copy-tooltip-copied', true)
    setTimeout(() => {
      if (copyId) {
        el.setAttribute('copy-tooltip', 'ID kopieren')
      } else {
        el.setAttribute('copy-tooltip', 'Kopieren')
      }
      el.setAttribute('copy-tooltip-copied', false)
    }, 2000)
    if (el.$copyCallback) {
      el.$copyCallback(el.$copyValue)
    }
  }
  el.addEventListener('click', handler)
  el.$destroyCopy = () => el.removeEventListener('click', handler)
}

export default defineNuxtPlugin((vueApp) => {
  const t = null
  tooltipDirective(vueApp.vueApp)
})
