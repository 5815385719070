/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Domain_TransitRequest = {
    /**
     * The transit type:
     * - connected: The nameservers will stay the same, the domain is usable as before.
     * - disconnected: The nameservers will be removed and a transit-parking page will be shown. The domain is not usable as before.
     *
     */
    type: Domain_TransitRequest.type;
};

export namespace Domain_TransitRequest {

    /**
     * The transit type:
     * - connected: The nameservers will stay the same, the domain is usable as before.
     * - disconnected: The nameservers will be removed and a transit-parking page will be shown. The domain is not usable as before.
     *
     */
    export enum type {
        CONNECTED = 'connected',
        DISCONNECTED = 'disconnected',
    }


}

