import { useAuth } from "~/stores/auth";

export default defineNuxtRouteMiddleware((to) => {
    if (to.path === '/') {
        if (useAuth().isAuthenticated) {
            return navigateTo('/domains')
        }

        return navigateTo('/auth/login')
    }
})
