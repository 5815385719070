import {notify} from "notiwind";

export default function useNotify() {
    const success = (title: string, message: string, timeout = 4000) => {
        notify({
            title,
            text: message,
            type: 'success',
        })
    }

    const error = (title: string, message: string, timeout = 4000) => {
        notify({
            title,
            text: message,
            type: 'error',
        })
    }

    const send = (title: string, message: string, type = 'success', timeout = 4000) => {
        notify({
            title,
            text: message,
            type
        }, timeout)
    }

    return {
        success,
        error,
        send
    }
}
