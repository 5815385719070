/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Ticket_CreateTicketRequest_ticket_create = {
    subject: string;
    group: Ticket_CreateTicketRequest_ticket_create.group;
    message: string;
    priority: Ticket_CreateTicketRequest_ticket_create.priority;
    tag: Ticket_CreateTicketRequest_ticket_create.tag | null;
};

export namespace Ticket_CreateTicketRequest_ticket_create {

    export enum group {
        NETZFABRIK_SUPPORT = 'NETZFABRIK Support',
        NETZFABRIK_SALES = 'NETZFABRIK Sales',
        NETZFABRIK_BILLING = 'NETZFABRIK Billing',
        NETZFABRIK_GENERAL_REQUESTS = 'NETZFABRIK General Requests',
    }

    export enum priority {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }

    export enum tag {
        SERVER_PROBLEMS = 'Server Problems',
        NETWORK_PROBLEMS = 'Network Problems',
        OTHER_PROBLEMS = 'Other Problems',
        VAT = 'VAT',
        PAYMENT = 'Payment',
    }


}

