import {createMessage, FormKitNode} from "@formkit/core";
import {OpenAPI} from "~/api-generated/core/OpenAPI";
import {useAuth} from "~/stores/auth";

/**
 * A contrived validation rule that checks if the value is a valid iban.
 */
const hostname = async function (node: FormKitNode) {
  node.store.remove('rule_hostname_ip_invalid')
  if (!node) {
    return false;
  }

  if (!node.context) {
    return true;
  }
  node.context.iconRight = ''

  if ((!node.value) && node.props.validationRules?.required) {
    return false;
  }

  if (!node.value) {
    return true;
  }

  const isIpValid = node.value.match(/^(\d{1,3}\.){3}\d{1,3}$/)
  if (isIpValid) {
    return true;
  }

  let isValid = true
  const value = node.value
  if (value.length > 255) {
    isValid = false
  }

  const parts = value.split('.')
  if (parts.some(part => part.length > 63)) {
    isValid = false
  }

  if (parts.length < 2) {
    isValid = false
  }

  if (parts.some(part => !part.toLowerCase().match(/^[a-z0-9-]+$/))) {
    isValid = false
  }

  if (parts[parts.length - 1].length < 2) {
    isValid = false
  }

  if (!isValid) {
    const errorMessage = createMessage({
      key: 'rule_hostname_ip_invalid',
      value: 'Der Wert muss ein gültiger Hostname/IP sein.',
      visible: true,
      blocking: true,
      type: 'validation'
    })

    node.store.set(errorMessage)

    return false;
  }

  return true;
}

hostname.blocking = true
hostname.skipEmpty = false
hostname.debounce = 20 // milliseconds
hostname.force = true

export default hostname
