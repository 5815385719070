/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DomainRegistrar = {
    readonly id?: string;
    type?: DomainRegistrar.type;
    username?: string;
    password?: string;
    createdAt?: string;
    updatedAt?: string;
};

export namespace DomainRegistrar {

    export enum type {
        INTERNET_X = 'INTERNET_X',
        DOMAIN_BESTELLSYSTEM = 'DOMAIN_BESTELLSYSTEM',
    }


}

