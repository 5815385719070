const serverHostname = function (node) {
  // allow only a-z, 0-9, -, _

  return node.value?.match(/^[a-z0-9-_.]+$/);
}

serverHostname.blocking = true
serverHostname.skipEmpty = false
serverHostname.debounce = 20 // milliseconds
serverHostname.force = true

export default serverHostname