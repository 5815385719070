<script setup lang="ts">
import type {PropType} from "vue";
import type {FormKitContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import {useDialog} from "primevue/usedialog";
import {useApiDropdown} from "~/stores/api-dropdown";
import DomainHandleCreateForm from "~/components/Enterprise/Domain/DomainHandleCreateForm.vue";
import AddSshKeyForm from "~/components/Enterprise/SshKey/AddSshKeyForm.vue";

const props = defineProps({
  context: Object as PropType<FormKitContext>
})

const dialog = useDialog()

const apiEndpoint = computed(() => {
  return '/datacenter/ssh-keys'
})

const headerComponentProps = {
  title: 'SSH Key hinzufügen',
  onClick: ({ refresh, setInputValue }) => {
    dialog.open(AddSshKeyForm, {
      props: {
        header: 'SSH Key hinzufügen',
        modal: true,
        draggable: false,
        closeOnEscape: true,
        contentStyle: {
          'min-width': '80vh',
        },
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await props.context?.attrs.onCreate?.(data.data)
          await refresh()
          useApiDropdown().addItem(data.data)
          setInputValue(data.data.id)
        }
      }
    })
  }
}
</script>

<template>
  <div>
    <ApiDropdown
        type="multi"
        :context="context"
        :api-endpoint="apiEndpoint"
        :preload-options="true"
        :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          value: data.id,
          label: data.name,
        }
      }"
        :default-placeholder="'SSH Key auswählen'"
        :header-component-props="headerComponentProps"
        :header-component="AppDropdownCreateButton"
    />
  </div>
</template>
