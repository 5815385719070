/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ZoneRecord_zone_read_zone_record_read = {
    readonly id?: string;
    name: string;
    type: ZoneRecord_zone_read_zone_record_read.type;
    content: string;
    ttl: number;
};

export namespace ZoneRecord_zone_read_zone_record_read {

    export enum type {
        A = 'A',
        AAAA = 'AAAA',
        CNAME = 'CNAME',
        TXT = 'TXT',
        MX = 'MX',
        SRV = 'SRV',
        NS = 'NS',
        SPF = 'SPF',
        SSHFP = 'SSHFP',
        SOA = 'SOA',
        CAA = 'CAA',
    }


}

