/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IsoImage = {
    readonly id?: string;
    readonly operatingSystem?: IsoImage.operatingSystem;
    readonly name?: string;
    readonly fileName?: string;
    readonly isAvailable?: boolean;
    readonly available?: boolean;
};

export namespace IsoImage {

    export enum operatingSystem {
        DEBIAN = 'debian',
        UBUNTU = 'ubuntu',
        CENTOS = 'centos',
        FEDORA = 'fedora',
        REDHAT = 'redhat',
        ROCKYLINUX = 'rockylinux',
        WINDOWS = 'windows',
    }


}

