/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ZoneRecordInput_zone_create_zone_record_create = {
    name: string;
    type: ZoneRecordInput_zone_create_zone_record_create.type;
    content: string;
    ttl: number;
    /**
     * Only used for MX records
     */
    priority?: number;
};

export namespace ZoneRecordInput_zone_create_zone_record_create {

    export enum type {
        A = 'A',
        AAAA = 'AAAA',
        CNAME = 'CNAME',
        TXT = 'TXT',
        MX = 'MX',
        SRV = 'SRV',
        NS = 'NS',
        SPF = 'SPF',
        SSHFP = 'SSHFP',
        SOA = 'SOA',
        CAA = 'CAA',
    }


}

