/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ZoneNameserver_zone_list } from './ZoneNameserver_zone_list';

export type Zone_zone_list = {
    readonly id?: string;
    name: string;
    /**
     * necessary for api platform
     */
    isDnssecEnabled: boolean;
    refresh: number;
    retry: number;
    expire: number;
    ttl: number;
    type?: Zone_zone_list.type;
    nameservers?: Array<ZoneNameserver_zone_list>;
};

export namespace Zone_zone_list {

    export enum type {
        STANDARD = 'standard',
        SLAVE = 'slave',
    }


}

