/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Get a bill history by bill id
 */
export type BillHistory = {
    id?: string;
    billId?: string;
    /**
     * The date when the bill from was created
     */
    from?: string;
    /**
     * The date when the bill to was created
     */
    to?: string;
    /**
     * The type of the billing
     */
    type?: BillHistory.type;
    includedBytes?: number;
    usedBytes?: number;
    overusedBytes?: number;
    /**
     * The current used traffic of the traffic plan in percent
     */
    usedInPercent?: number;
};

export namespace BillHistory {

    /**
     * The type of the billing
     */
    export enum type {
        CDR = 'CDR',
        QUOTA = 'QUOTA',
    }


}

