<script setup lang="ts">
import {$fetch} from "ofetch";
import AppButton from "~/components/Enterprise/AppButton.vue";
import {useAuth} from "~/stores/auth";

const dialogRef = inject('dialogRef')

const isLoading = ref(false)
const onSubmit = async (data: any) => {
  isLoading.value = true
  await $fetch(useRuntimeConfig().public.apiRootUrl + '/datacenter/ssh-keys', {
    method: 'POST',
    body: data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + useAuth().getAccessToken,
    },
    onResponse({ response }) {
      if (response.status === 201) {
        dialogRef?.value?.close(response._data)

        useNotify().success('SSH Key hinzugefügt', 'Der SSH Key wurde erfolgreich hinzugefügt.')
      } else {
        useNotify().error('SSH Key hinzufügen fehlgeschlagen', 'Der SSH Key konnte nicht hinzugefügt werden.')
      }

      isLoading.value = false
    }
  })
}

const name = ref('')
const nameModified = ref(false)
const onPublicKeyUpdated = (value: string) => {
  if (nameModified.value && name.value !== '') return

  name.value = value.target.value.split(' ')[2]
}
</script>

<template>
  <div class="w-full">
    <FormKit type="form" @submit="onSubmit" :actions="false">
      <FormKit type="textarea" name="publicKey" label="Öffentlicher Schlüssel" placeholder="Öffentlicher Schlüssel"
               @keyup="onPublicKeyUpdated"
               validation="required"
      />

      <FormKit type="text" name="name" label="Name" placeholder="Name"
               v-model="name"
               validation="required"
               @keydown="nameModified = true"
      />

      <AppButton button-type="submit" class="mt-4 w-full !mb-0 !mt-0" :is-loading="isLoading">Hinzufügen</AppButton>
    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>