/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IpAddress_webspace_read = {
    ipAddress?: string;
    type?: IpAddress_webspace_read.type;
};

export namespace IpAddress_webspace_read {

    export enum type {
        IPV4 = 'ipv4',
        IPV6 = 'ipv6',
    }


}

