/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DomainRegistrarHandle } from './DomainRegistrarHandle';
import type { PhoneNumber } from './PhoneNumber';

export type Handle = {
    readonly id?: string;
    firstname?: string;
    lastname?: string;
    company?: string | null;
    address?: string;
    city?: string;
    zipcode?: string;
    /**
     * ISO 3166-1 alpha-2 country code
     */
    countryCode?: string;
    email?: string;
    note?: string | null;
    verified?: boolean;
    /**
     * The phone prefix, e.g. +49 for Germany
     */
    phone1?: string;
    /**
     * The area code
     */
    phone2?: string;
    /**
     * The phone number
     */
    phone3?: string;
    /**
     * The fax prefix, e.g. +49 for Germany
     */
    fax1?: string | null;
    /**
     * The area code
     */
    fax2?: string | null;
    /**
     * The fax number
     */
    fax3?: string | null;
    type?: Handle.type;
    readonly registrarRelations?: Array<DomainRegistrarHandle>;
    createdAt?: string;
    updatedAt?: string;
    customer?: string | null;
    readonly phone?: PhoneNumber;
    readonly fax?: PhoneNumber;
};

export namespace Handle {

    export enum type {
        ORG = 'ORG',
        PERS = 'PERS',
        ROLE = 'ROLE',
    }


}

