/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ZoneRecord_zone_record_update = {
    name: string;
    type: ZoneRecord_zone_record_update.type;
    content: string;
    ttl: number;
};

export namespace ZoneRecord_zone_record_update {

    export enum type {
        A = 'A',
        AAAA = 'AAAA',
        CNAME = 'CNAME',
        TXT = 'TXT',
        MX = 'MX',
        SRV = 'SRV',
        NS = 'NS',
        SPF = 'SPF',
        SSHFP = 'SSHFP',
        SOA = 'SOA',
        CAA = 'CAA',
    }


}

