import { defineStore } from "pinia";
import {
  Account_user_read_user_me_read_account_me_customer_me, Customer_user_read_user_me_read_account_me_customer_me,
  LoginCheckService,
  User_UserMeResponse_user_read_user_me_read_account_me_customer_me,
  UserService
} from "~/api-generated";
import { OpenAPI } from "~/api-generated/core/OpenAPI";

// @ts-ignore
export const useAuth = defineStore("auth", {
  state: () => ({
    accessToken: null as string | null,
    user: null as User_UserMeResponse_user_read_user_me_read_account_me_customer_me | null,
    redirectUrl: null as string | null,
  }),
  getters: {
    isAuthenticated(): boolean {
      return this.accessToken !== null;
    },
    getAccessToken(): string | null {
      return this.accessToken;
    },
    getRedirect(): string | null {
      return this.redirectUrl;
    },
    getAccount(): Account_user_read_user_me_read_account_me_customer_me | null {
      return this.user?.account ?? null;
    },
    getCustomer(): Customer_user_read_user_me_read_account_me_customer_me | null {
      return this.user?.customer ?? null;
    },
    getUserName(): string {
      return (this.user?.user.firstName ?? '') + ' ' + (this.user?.user.lastName ?? '')
    },
    getEmail(): string | null {
        return this.user?.user.email ?? null;
    }
  },
  actions: {
    hasRole(role: string): boolean {
        if (!this.user) return false;

        return this.user?.roles?.includes(role) ?? false;
    },
    redirectOnLogin(redirectUrl: string) {
      this.redirectUrl = redirectUrl;
    },
    async login(email: string, password: string) {
      const loginResponse = await LoginCheckService.loginCheckPost({
        requestBody: {
            email,
            password
        }
      });

      this.setAccessToken(loginResponse?.token);

      await this.loadUser();

      return Promise.resolve();
    },
    setAccessToken(accessToken: string) {
      this.accessToken = accessToken;
      OpenAPI.TOKEN = accessToken;
    },
    logout() {
      const { $posthog } = useNuxtApp()
      if ($posthog) {
        const posthog = $posthog()
        posthog.reset();
      }
      const { $sentrySetUser } = useNuxtApp()
      $sentrySetUser(null);

      this.user = null;
      this.accessToken = null;
      this.redirectUrl = null;
      OpenAPI.TOKEN = undefined;
    },
    async loadUser() {
      OpenAPI.TOKEN = this.accessToken ?? undefined;

      await UserService.getMe().then((userResponse) => {
          if (!userResponse || !userResponse.user) throw new Error('User not found');

          this.user = userResponse;
          const { $posthog } = useNuxtApp()
          if ($posthog) {
            const posthog = $posthog()
            posthog.identify(this.user?.user.id, {
              email: this.user?.user.email ?? null,
              name: (this.user?.user.firstName ?? '') + ' ' + (this.user?.user.lastName ?? ''),
            })
          }
        const { $sentrySetUser } = useNuxtApp()
        $sentrySetUser({
          id: this.user?.user.id,
          email: this.user?.user.email,
        });
      }).catch(() => {
            this.logout();
      });

      return Promise.resolve();
    }
  },
  persistedState: {
    persist: true,
  },
});
