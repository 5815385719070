import validate from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45redirect_45global from "/builds/netzfabrik/apanel/backbone-api/app/middleware/0.redirect.global.ts";
import auth_45global from "/builds/netzfabrik/apanel/backbone-api/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/builds/netzfabrik/apanel/backbone-api/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45redirect_45global,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}