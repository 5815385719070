<script setup lang="ts">
import {DomainService} from "~/api-generated";
import useNotify from "~/composables/useNotify";
import {useEmitter} from "~/utils/mitt";
import AppButton from "~/components/Enterprise/AppButton.vue";

const dialogRef = inject('dialogRef')

const emit = defineEmits(['handleCreated'])

const onSubmit = async (form: any) => {
  await DomainService.createHandle({ requestBody: {
      ...form,
      phone2: form.phone2?.trim().length === 0 ? null : form.phone2.toString(),
      phone3: form.phone3?.trim().length === 0 ? null : form.phone3.toString(),
      fax2: form.fax2?.trim().length === 0 ? null : form.fax2.toString(),
      fax3: form.fax3?.trim().length === 0 ? null : form.fax3.toString(),
      company: form.company?.trim().length === 0 ? null : form.company,
    } }).then(async (response) => {
    useNotify().success('Handle wurde erstellt', 'Handle wurde erfolgreich erstellt')
    emit('handleCreated')
    useEmitter().emit('handle.written')
    dialogRef.value.close(response)
  });
}


const countries = [
  {
    value: 'DE',
    label: '🇩🇪 Deutschland',
  },
  {
    value: 'AT',
    label: '🇦🇹 Österreich',
  },
  {
    value: 'CH',
    label: '🇨🇭 Schweiz',
  },
]

const PhoneCountries = [
  {
    value: '+49',
    label: '🇩🇪 +49',
  },
  {
    value: '+43',
    label: '🇦🇹 +43',
  },
  {
    value: '+41',
    label: '🇨🇭 +41',
  },
]

const defaultValue = {
  name: '',
  type: 'PERS',
  firstname: '',
  lastname: '',
  company: '',
  street: '',
  zip: '',
  city: '',
  email: '',
  phone1: '',
  phone2: '',
  phone3: '',
  fax1: '',
  fax2: '',
  fax3: '',
}
</script>

<template>
  <div>
    <FormKit type="form" :actions="false" @submit="onSubmit">
      <div class="flex justify-between flex-col">
        <div class="flex-1">
          <div class="grid grid-cols-1 gap-2">
            <FormKit
                type="primeDropdown"
                name="type"
                label="Typ"
                :options="[
            { value: 'ORG', label: 'Firma' },
            { value: 'PERS', label: 'Privatperson' },
          ]"
                validation="required"
            />
            <FormKit
                type="primeInputText"
                name="company"
                label="Firma"
                validation="optional"
            />

            <div class="grid md:grid-cols-2 gap-4">
              <FormKit
                  type="primeInputText"
                  name="firstname"
                  label="Vorname"
                  validation="required"
              />
              <FormKit
                  type="primeInputText"
                  name="lastname"
                  label="Nachname"
                  validation="required"
              />
            </div>

            <FormKit
                type="primeInputText"
                name="address"
                label="Adresse"
                validation="required"
            />

            <div class="grid md:grid-cols-2 gap-4">
              <FormKit
                  type="primeInputText"
                  name="zipcode"
                  label="PLZ"
                  validation="required"
              />
              <FormKit type="primeInputText" name="city" label="Ort" validation="required" />
            </div>

            <FormKit
                type="primeDropdown"
                name="countryCode"
                label="Land"
                :options="countries"
                validation="required"
            />

            <FormKit
                type="primeInputText"
                name="email"
                label="E-Mail"
                validation="required|email"
            />
            Telefonnummer
            <div class="flex gap-2">

              <FormKit
                  type="primeDropdown"
                  name="phone1"
                  label="Land"
                  :classes="{
            'input': 'w-1/3',
              }"
                  :options="PhoneCountries"
                  :validation="[['required'], ['matches', /^\+[0-9]{1,4}$/]]"

              />
              <FormKit
                  type="primeInputNumber"
                  name="phone2"
                  number="integer"
                  label="Vorwahl"
                  validation="required"
              />
              <FormKit
                  type="primeInputNumber"
                  name="phone3"
                  number="integer"
                  label="Nummer"
                  validation="required"
              />
            </div>
            Faxnummer
            <div class="flex gap-2">

              <FormKit
                  type="primeDropdown"
                  name="fax1"
                  label="Land"
                  :classes="{
            'input': 'w-1/3',
              }"
                  :options="PhoneCountries"
                  :validation="[['required'], ['matches', /^\+[0-9]{1,4}$/]]"

              />
              <FormKit
                  type="primeInputNumber"
                  name="fax2"
                  label="Vorwahl"
                  number="integer"
                  validation="required"
              />
              <FormKit
                  type="primeInputNumber"
                  name="fax3"
                  number="integer"
                  label="Nummer"
                  validation="required"
              />
            </div>
          </div>
        </div>

        <AppButton button-type="submit">Kontakt anlegen</AppButton>
      </div>
    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>