<script setup lang='ts'>
const props = defineProps({
  context: Object,
})

const context = props.context
const attrs = computed(() => context?.attrs)

function handleBlur(e: any) {
  context?.handlers.blur(e.target.value)
}

function handleInput(e: any) {
  context?.node.input(e.target.value)
}

const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid) ? `${attrs.value?.class} p-invalid` : attrs.value?.class)
</script>

<template>
  <div class="p-formkit">
    <Password
        v-model="context._value"
        :input-id="context.id"
        :disabled="attrs._disabled ?? false"
        :readonly="attrs._readonly ?? false"
        :input-style="attrs.style"
        :input-class="styleClass"
        :tabindex="attrs.tabindex"
        :aria-label="attrs.ariaLabel"
        :aria-labelledby="attrs.ariaLabelledby"
        :placeholder="attrs.placeholder"
        :medium-regex="attrs.mediumRegex ?? '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'"
        :strong-regex="attrs.strongRegex ?? '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'"
        :prompt-label="attrs.promptLabel"
        :weak-label="attrs.weakLabel ?? 'Schwach'"
        :medium-label="attrs.mediumLabel ?? 'Mittel'"
        :strong-label="attrs.strongLabel ?? 'Stark'"
        :hide-icon="attrs.hideIcon ?? 'pi pi-eye-slash'"
        :show-icon="attrs.showIcon ?? 'pi pi-eye'"
        :feedback="context.feedback ?? true"
        :toggle-mask="context.toggleMask ?? false"
        :pt="attrs.pt"
        :pt-options="attrs.ptOptions"
        :unstyled="attrs.unstyled ?? false"
        @input="handleInput"
        @blur="handleBlur"
    >
      <template #header>
        <h6>Sicheres Passwort eingeben</h6>
      </template>
      <template #footer>
        <Divider />
        <p class="mt-2">Anforderungen</p>
        <ul class="pl-2 ml-2 mt-0 list-disc" style="line-height: 1.5">
          <li>Mindestens ein Sonderzeichen</li>
          <li>Mindestens ein Großbuchstabe</li>
          <li>Mindestens ein Kleinbuchstabe</li>
          <li>Mindestens eine Ziffer</li>
          <li>Mindestens 8 Zeichen</li>
        </ul>
      </template>
    </Password>
  </div>
</template>