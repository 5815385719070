import Ripple from 'primevue/ripple';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import CodeHighlight from '../directives/code';
import DialogService from "primevue/dialogservice";
import DynamicDialog from "primevue/dynamicdialog";
import Editor from "primevue/editor";
import FocusTrap from "primevue/focustrap";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import Message from "primevue/message";
import InputNumber from "primevue/inputnumber";
import TabMenu from "primevue/tabmenu";
import ProgressSpinner from "primevue/progressspinner";
import Password from "primevue/password";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Badge from "primevue/badge";

export declare interface AppState {
  theme?: string;
  darkTheme?: boolean;
}

export default defineNuxtPlugin(({ vueApp: app }) => {
  app.use(PrimeVue, useAppConfig().primevueConfig);

  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(DialogService);

  app.component('DynamicDialog', DynamicDialog);
  app.component('Editor', Editor);
  app.component('InputText', InputText);
  app.component('Calendar', Calendar);
  app.component('Checkbox', Checkbox);
  app.component('Message', Message);
  app.component('InputNumber', InputNumber);
  app.component('Dropdown', Dropdown);
  app.component('ProgressSpinner', ProgressSpinner);
  app.component('TabMenu', TabMenu);
  app.component('Password', Password);
  app.component('Textarea', Textarea);

  app.directive('tooltip', Tooltip);
  app.directive('ripple', Ripple);
  app.directive('code', CodeHighlight);
  app.directive('badge', BadgeDirective);
  app.directive('styleclass', StyleClass);
  app.directive('focustrap', FocusTrap);
});