/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Invoice_invoice_list = {
    id?: string;
    number?: string;
    status?: Invoice_invoice_list.status;
    dueDate?: string;
    invoiceDate?: string;
    netAmount?: number;
    grossAmount?: number;
    currency?: string;
};

export namespace Invoice_invoice_list {

    export enum status {
        DRAFT = 'draft',
        OPEN = 'open',
        PAID = 'paid',
        OVERDUE = 'overdue',
        CANCELLED = 'cancelled',
    }


}

