// formkit.config.ts
import { de } from '@formkit/i18n'
import {defineFormKitConfig} from '@formkit/vue'
import { createProPlugin, inputs } from "@formkit/pro";
import { generateClasses } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
import genesis from '@formkit/themes/tailwindcss/genesis'
import {appInputs} from "~/formkit/inputs";
import { primeInputs } from '@sfxcode/formkit-primevue'
import {rules} from "~/formkit/rules";


const pro = createProPlugin('fk-8dacbcafc13', inputs)


const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist'];

function addAsteriskPlugin (node) {
    if (['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(node.props.type)) return;

    node.on('created', () => {
        const legendOrLabel = legends.includes(`${node.props.type}${node.props.options ? '_multi' : ''}`) ? 'legend' : 'label';

        node.props.definition.schemaMemoKey = `required_${node.props.definition.schemaMemoKey}`

        node.context.isRequired = node.props.parsedRules.some(rule => rule.name === 'required')

        node.on('prop:validation', () => {
            node.context.isRequired = node.props.parsedRules.some(rule => rule.name === 'required')
        })

        const schemaFn = node.props.definition.schema
        node.props.definition.schema = (sectionsSchema = {}) => {
            sectionsSchema[legendOrLabel] = {
                children: ['$label', {
                    $el: 'span',
                    if: '$isRequired',
                    attrs: {
                        class: 'text-netzfabrik-indigo',
                    },
                    children: [' *']
                }]
            }

            return schemaFn(sectionsSchema)
        }
    })
}

export default defineFormKitConfig({locales: { de },
    locale: 'de',
    icons: {
        ...genesisIcons,
    },
    config: {
        classes: generateClasses({
            ...genesis,
            global: {
                ...genesis.global,
                inner: genesis.global.inner + ' bg-white dark:bg-gray-800',
            },
        }),
    },
    plugins: [pro, addAsteriskPlugin],
    iconLoaderUrl: (iconName) => `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free/svgs/solid/${iconName}.svg`,
    inputs: {
        ...primeInputs,
        ...appInputs,
    },
    rules: {
        ...rules,
    }
})