/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PaymentMethod_invoice_read } from './PaymentMethod_invoice_read';

export type Invoice_invoice_read = {
    id?: string;
    number?: string;
    status?: Invoice_invoice_read.status;
    dueDate?: string;
    invoiceDate?: string;
    netAmount?: number;
    grossAmount?: number;
    currency?: string;
    paymentMethod?: PaymentMethod_invoice_read;
};

export namespace Invoice_invoice_read {

    export enum status {
        DRAFT = 'draft',
        OPEN = 'open',
        PAID = 'paid',
        OVERDUE = 'overdue',
        CANCELLED = 'cancelled',
    }


}

