/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Handle_domain_read_handle_read_nameserver_read = {
    readonly id?: string;
    firstname?: string;
    lastname?: string;
    company?: string | null;
    address?: string;
    city?: string;
    zipcode?: string;
    /**
     * ISO 3166-1 alpha-2 country code
     */
    countryCode?: string;
    email?: string;
    note?: string | null;
    /**
     * The phone prefix, e.g. +49 for Germany
     */
    phone1?: string;
    /**
     * The area code
     */
    phone2?: string;
    /**
     * The phone number
     */
    phone3?: string;
    /**
     * The fax prefix, e.g. +49 for Germany
     */
    fax1?: string | null;
    /**
     * The area code
     */
    fax2?: string | null;
    /**
     * The fax number
     */
    fax3?: string | null;
    type?: Handle_domain_read_handle_read_nameserver_read.type;
};

export namespace Handle_domain_read_handle_read_nameserver_read {

    export enum type {
        ORG = 'ORG',
        PERS = 'PERS',
        ROLE = 'ROLE',
    }


}

