/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Domain_domain_list = {
    readonly id?: string;
    readonly name?: string;
    readonly isDomainTransferLocked?: boolean;
    readonly authCode?: string | null;
    readonly authCodeExpiryDate?: string | null;
    readonly isAutoRenewEnabled?: boolean;
    readonly registrationDate?: string;
    readonly status?: Domain_domain_list.status;
    readonly transitType?: Domain_domain_list.transitType | null;
    readonly dnsZoneId?: string | null;
};

export namespace Domain_domain_list {

    export enum status {
        CREATED = 'created',
        PENDING = 'pending',
        ONLINE = 'online',
        DELETED = 'deleted',
        PENDING_DELETE = 'pendingDelete',
        FAILED_NAMESERVER = 'failedNameserver',
        FAILED_REGISTRATION = 'failedRegistration',
        FAILED_TRANSFER = 'failedTransfer',
        UNKNOWN = 'unknown',
        TRANSFERED_OUT = 'transferedOut',
        TRANSIT = 'transit',
        PENDING_TRANSIT = 'pendingTransit',
        ZONE_CREATED = 'zoneCreated',
    }

    export enum transitType {
        CONNECTED = 'connected',
        DISCONNECTED = 'disconnected',
    }


}

