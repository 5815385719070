/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Contract = {
    readonly id?: string;
    name?: string;
    description?: string | null;
    price?: number;
    currency?: Contract.currency;
    priceInterval?: Contract.priceInterval;
    billingInterval?: Contract.billingInterval;
    status?: Contract.status;
    readonly createdAt?: string;
    readonly updatedAt?: string;
    readonly canceledAt?: string | null;
    readonly cancelationDate?: string | null;
    readonly formattedPrice?: string;
};

export namespace Contract {

    export enum currency {
        EUR = 'EUR',
        USD = 'USD',
    }

    export enum priceInterval {
        MONTHLY = 'monthly',
        YEARLY = 'yearly',
    }

    export enum billingInterval {
        MONTHLY = 'monthly',
        YEARLY = 'yearly',
    }

    export enum status {
        PENDING = 'pending',
        ACTIVE = 'active',
        CANCELLED = 'cancelled',
        EXPIRED = 'expired',
    }


}

