/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Bucket } from './Bucket';

export type AccessKey = {
    readonly id?: string;
    readonly accessKey?: string;
    readonly secretKey?: string;
    readonly region?: AccessKey.region;
    readonly bucket?: Bucket | null;
    readonly customer?: string | null;
    createdAt?: string;
    updatedAt?: string;
};

export namespace AccessKey {

    export enum region {
        DE_WEST_CENTRAL_1 = 'de-west-central-1',
        DE_WEST_CENTRAL_2 = 'de-west-central-2',
    }


}

