/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IpAddress_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read } from './IpAddress_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read';
import type { Region_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read } from './Region_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read';
import type { ServiceLevelAgreement_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read } from './ServiceLevelAgreement_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read';
import type { VirtualMachineTask_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read } from './VirtualMachineTask_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read';

export type VirtualMachine_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read = {
    readonly id?: string;
    tasks?: Array<VirtualMachineTask_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read>;
    readonly status?: VirtualMachine_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read.status;
    name?: string;
    cpu?: number;
    ramMb?: number;
    diskMb?: number;
    ipV4?: IpAddress_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read | null;
    ipV6?: IpAddress_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read | null;
    additionalIpV4?: Array<IpAddress_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read>;
    additionalIpV6?: Array<IpAddress_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read>;
    architecture?: VirtualMachine_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read.architecture;
    /**
     * UUID of the virtual machine
     */
    readonly vmUuid?: string | null;
    readonly serviceLevelAgreement?: ServiceLevelAgreement_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read | null;
    createdAt?: string;
    updatedAt?: string;
    /**
     * Availability zone of the cloud server
     */
    readonly region?: Region_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read;
};

export namespace VirtualMachine_cloud_server_read_service_level_agreement_read_region_read_timestampable_created_at_read_timestampable_updated_at_read_virtual_machine_task_read {

    export enum status {
        PENDING = 'pending',
        STARTING = 'starting',
        CREATING = 'creating',
        RUNNING = 'running',
        REBOOTING = 'rebooting',
        STOPPING = 'stopping',
        STOPPED = 'stopped',
        DELETING = 'deleting',
        SHUTTING_DOWN = 'shutting-down',
        DELETED = 'deleted',
    }

    export enum architecture {
        X86_64 = 'x86_64',
        AARCH64 = 'aarch64',
    }


}

