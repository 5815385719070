/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ZoneNameserver_zone_read } from './ZoneNameserver_zone_read';
import type { ZoneRecord_zone_read } from './ZoneRecord_zone_read';

export type Zone_zone_read = {
    readonly id?: string;
    name: string;
    /**
     * necessary for api platform
     */
    isDnssecEnabled: boolean;
    refresh: number;
    retry: number;
    expire: number;
    ttl: number;
    type?: Zone_zone_read.type;
    /**
     * IP addresses of master DNS servers. Required for slave zones.
     */
    masterIpAddresses?: Array<string> | null;
    readonly records?: Array<ZoneRecord_zone_read>;
    nameservers?: Array<ZoneNameserver_zone_read>;
};

export namespace Zone_zone_read {

    export enum type {
        STANDARD = 'standard',
        SLAVE = 'slave',
    }


}

