import {defineStore} from "pinia";

export const useApiDropdown = defineStore('api-dropdown', {
  state: () => ({
    foundItems: [],
  }),
  actions: {
    async addItem(item) {
      if (!item || !item?.id) {
        return
      }

      const index = this.foundItems.findIndex((c) => c.id === item.id)
      if (index === -1) {
        this.foundItems.push(item)
      } else {
        this.foundItems[index] = item
      }
    }
  },
  getters: {
    getById: (state) => (id) => {
      return state.foundItems.find((c) => c.id === id) || null
    }
  }
})
