<script setup lang="ts">
import type {PropType} from "vue";
import type {FormKitContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import {useDialog} from "primevue/usedialog";
import {useApiDropdown} from "~/stores/api-dropdown";
import DomainHandleCreateForm from "~/components/Enterprise/Domain/DomainHandleCreateForm.vue";

const props = defineProps({
  context: Object as PropType<FormKitContext>
})

const dialog = useDialog()

const apiEndpoint = computed(() => {
  return '/handles'
})

const headerComponentProps = {
  title: 'Kontakt hinzufügen',
  onClick: ({ refresh, setInputValue }) => {
    dialog.open(DomainHandleCreateForm, {
      props: {
        header: 'Kontakt hinzufügen',
        modal: true,
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await props.context?.attrs.onCreate?.(data.data)
          await refresh()
          useApiDropdown().addItem(data.data)
          setInputValue(data.data.id)
        }
      }
    })
  }
}
</script>

<template>
  <div>
    <ApiDropdown
        type="single"
        :context="context"
        :api-endpoint="apiEndpoint"
        :preload-options="true"
        :api-transform-function="(data: any, { lang }) => {
          const company = data.company ? data.company : undefined
          const firstName = data.firstname ? data.firstname : undefined
          const lastName = data.lastname ? data.lastname : undefined

          const name = firstName && lastName ? (firstName + ' ' + lastName) : ''

          let label = ''
          if (company) {
            label = company
          }

          if (name) {
            label = label ? (label + ' - ' + name) : name
          }

          label += ' (' + data.address + ' ' + data.zipcode + ' ' + data.city + ')'
        return {
          ...data,
          value: data.id,
          label,
        }
      }"
        :default-placeholder="'Kontakt auswählen'"
        :header-component-props="headerComponentProps"
        :header-component="AppDropdownCreateButton"
    />
  </div>
</template>
