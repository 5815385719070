import {FormKitTypeDefinition} from "@formkit/core";
import {createInput} from "@formkit/vue";
import HandleDropdown from "~/formkit/inputs/HandleDropdown.vue";
import ButtonRadioGroup from "~/formkit/inputs/ButtonRadioGroup.vue";
import SshKeyDropdown from "~/formkit/inputs/SshKeyDropdown.vue";
import PasswordWithHint from "~/formkit/inputs/PasswordWithHint.vue";

export const handleDropdownDefinition: FormKitTypeDefinition = createInput(HandleDropdown, {
  props: [],
  family: 'bboneDropdown',
})

export const sshKeyDropdownDefinition: FormKitTypeDefinition = createInput(SshKeyDropdown, {
  props: [],
  family: 'bboneDropdown',
})

export const buttonRadioGroupDefinition: FormKitTypeDefinition = createInput(ButtonRadioGroup, {
  props: [],
  family: 'bboneButtonRadioGroup',
})

export const passwordHintDefinition: FormKitTypeDefinition = createInput(PasswordWithHint, {
  props: [],
  family: 'bbonePasswordHint',
  });

export const appInputs = {
  handleDropdown: handleDropdownDefinition,
  buttonRadioGroup: buttonRadioGroupDefinition,
  sshKeyDropdown: sshKeyDropdownDefinition,
  passwordHint: passwordHintDefinition,
}
