import { default as logingKX2xymzKHMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/auth/login.vue?macro=true";
import { default as _91token_93ezJoLNPvynMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/auth/password-recovery/[token].vue?macro=true";
import { default as _91provider_93eVU9Ozo6bNMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/auth/social-login/[provider].vue?macro=true";
import { default as indexV3DmQcS9PPMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/billing/invoices/index.vue?macro=true";
import { default as indexOQKDv7WKXfMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/billing/orders/index.vue?macro=true";
import { default as _91id_93CCa4zceZhZMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/cloud-servers/[id].vue?macro=true";
import { default as indexH6fHf7bRqsMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/cloud-servers/index.vue?macro=true";
import { default as newy5EgS24EWFMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/cloud-servers/new.vue?macro=true";
import { default as _91id_931fVva2jLgRMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/domains/[id].vue?macro=true";
import { default as _91id_93Zn26K2LPywMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/domains/dns-zones/[id].vue?macro=true";
import { default as indexjO5mGRUwY5Meta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/domains/dns-zones/index.vue?macro=true";
import { default as indexP83Y4BqhF5Meta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/domains/handles/index.vue?macro=true";
import { default as indexNo75SNrdi9Meta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/domains/index.vue?macro=true";
import { default as index2Jqf19jzHtMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/domains/reseller/index.vue?macro=true";
import { default as index2pMIHKjwaFMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/emails/index.vue?macro=true";
import { default as _91id_93Nx7XFUlujDMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/managed-apps/[id].vue?macro=true";
import { default as indexMrBGV59dCHMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/managed-apps/index.vue?macro=true";
import { default as index6EF9PDELFWMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/networks/index.vue?macro=true";
import { default as indexF4ab2vaKHRMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/object-storages/index.vue?macro=true";
import { default as _91id_93894SQ5F8pEMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/services/[id].vue?macro=true";
import { default as indexVBexc7dJR3Meta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/services/index.vue?macro=true";
import { default as indexTaQx1AveD8Meta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/support/index.vue?macro=true";
import { default as _91id_93QEWapsxfxmMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/support/tickets/[id].vue?macro=true";
import { default as indexlLlAHCRd7yMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/traffic/bills/[id]/history/index.vue?macro=true";
import { default as indexynxYGxH5p2Meta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/traffic/bills/[id]/index.vue?macro=true";
import { default as indexbxDLlDG7ZGMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/traffic/bills/index.vue?macro=true";
import { default as api_45tokensFfRaQvBBZ2Meta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/user/api-tokens.vue?macro=true";
import { default as data_45processing_45agreementEAvFfLIwQzMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/user/data-processing-agreement.vue?macro=true";
import { default as indexggdRd11EUoMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/user/index.vue?macro=true";
import { default as login_45activitieshOa3AWD7IGMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/user/login-activities.vue?macro=true";
import { default as _91id_93BgzaxLIvgNMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/webspaces/[id].vue?macro=true";
import { default as indexvKUItpvWfLMeta } from "/builds/netzfabrik/apanel/backbone-api/app/pages/webspaces/index.vue?macro=true";
export default [
  {
    name: logingKX2xymzKHMeta?.name ?? "auth-login",
    path: logingKX2xymzKHMeta?.path ?? "/auth/login",
    meta: logingKX2xymzKHMeta || {},
    alias: logingKX2xymzKHMeta?.alias || [],
    redirect: logingKX2xymzKHMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93ezJoLNPvynMeta?.name ?? "auth-password-recovery-token",
    path: _91token_93ezJoLNPvynMeta?.path ?? "/auth/password-recovery/:token()",
    meta: _91token_93ezJoLNPvynMeta || {},
    alias: _91token_93ezJoLNPvynMeta?.alias || [],
    redirect: _91token_93ezJoLNPvynMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/auth/password-recovery/[token].vue").then(m => m.default || m)
  },
  {
    name: _91provider_93eVU9Ozo6bNMeta?.name ?? "auth-social-login-provider",
    path: _91provider_93eVU9Ozo6bNMeta?.path ?? "/auth/social-login/:provider()",
    meta: _91provider_93eVU9Ozo6bNMeta || {},
    alias: _91provider_93eVU9Ozo6bNMeta?.alias || [],
    redirect: _91provider_93eVU9Ozo6bNMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/auth/social-login/[provider].vue").then(m => m.default || m)
  },
  {
    name: indexV3DmQcS9PPMeta?.name ?? "billing-invoices",
    path: indexV3DmQcS9PPMeta?.path ?? "/billing/invoices",
    meta: indexV3DmQcS9PPMeta || {},
    alias: indexV3DmQcS9PPMeta?.alias || [],
    redirect: indexV3DmQcS9PPMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/billing/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: indexOQKDv7WKXfMeta?.name ?? "billing-orders",
    path: indexOQKDv7WKXfMeta?.path ?? "/billing/orders",
    meta: indexOQKDv7WKXfMeta || {},
    alias: indexOQKDv7WKXfMeta?.alias || [],
    redirect: indexOQKDv7WKXfMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/billing/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CCa4zceZhZMeta?.name ?? "cloud-servers-id",
    path: _91id_93CCa4zceZhZMeta?.path ?? "/cloud-servers/:id()",
    meta: _91id_93CCa4zceZhZMeta || {},
    alias: _91id_93CCa4zceZhZMeta?.alias || [],
    redirect: _91id_93CCa4zceZhZMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/cloud-servers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexH6fHf7bRqsMeta?.name ?? "cloud-servers",
    path: indexH6fHf7bRqsMeta?.path ?? "/cloud-servers",
    meta: indexH6fHf7bRqsMeta || {},
    alias: indexH6fHf7bRqsMeta?.alias || [],
    redirect: indexH6fHf7bRqsMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/cloud-servers/index.vue").then(m => m.default || m)
  },
  {
    name: newy5EgS24EWFMeta?.name ?? "cloud-servers-new",
    path: newy5EgS24EWFMeta?.path ?? "/cloud-servers/new",
    meta: newy5EgS24EWFMeta || {},
    alias: newy5EgS24EWFMeta?.alias || [],
    redirect: newy5EgS24EWFMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/cloud-servers/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_931fVva2jLgRMeta?.name ?? "domains-id",
    path: _91id_931fVva2jLgRMeta?.path ?? "/domains/:id()",
    meta: _91id_931fVva2jLgRMeta || {},
    alias: _91id_931fVva2jLgRMeta?.alias || [],
    redirect: _91id_931fVva2jLgRMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/domains/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Zn26K2LPywMeta?.name ?? "domains-dns-zones-id",
    path: _91id_93Zn26K2LPywMeta?.path ?? "/domains/dns-zones/:id()",
    meta: _91id_93Zn26K2LPywMeta || {},
    alias: _91id_93Zn26K2LPywMeta?.alias || [],
    redirect: _91id_93Zn26K2LPywMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/domains/dns-zones/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjO5mGRUwY5Meta?.name ?? "domains-dns-zones",
    path: indexjO5mGRUwY5Meta?.path ?? "/domains/dns-zones",
    meta: indexjO5mGRUwY5Meta || {},
    alias: indexjO5mGRUwY5Meta?.alias || [],
    redirect: indexjO5mGRUwY5Meta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/domains/dns-zones/index.vue").then(m => m.default || m)
  },
  {
    name: indexP83Y4BqhF5Meta?.name ?? "domains-handles",
    path: indexP83Y4BqhF5Meta?.path ?? "/domains/handles",
    meta: indexP83Y4BqhF5Meta || {},
    alias: indexP83Y4BqhF5Meta?.alias || [],
    redirect: indexP83Y4BqhF5Meta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/domains/handles/index.vue").then(m => m.default || m)
  },
  {
    name: indexNo75SNrdi9Meta?.name ?? "domains",
    path: indexNo75SNrdi9Meta?.path ?? "/domains",
    meta: indexNo75SNrdi9Meta || {},
    alias: indexNo75SNrdi9Meta?.alias || [],
    redirect: indexNo75SNrdi9Meta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/domains/index.vue").then(m => m.default || m)
  },
  {
    name: index2Jqf19jzHtMeta?.name ?? "domains-reseller",
    path: index2Jqf19jzHtMeta?.path ?? "/domains/reseller",
    meta: index2Jqf19jzHtMeta || {},
    alias: index2Jqf19jzHtMeta?.alias || [],
    redirect: index2Jqf19jzHtMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/domains/reseller/index.vue").then(m => m.default || m)
  },
  {
    name: index2pMIHKjwaFMeta?.name ?? "emails",
    path: index2pMIHKjwaFMeta?.path ?? "/emails",
    meta: index2pMIHKjwaFMeta || {},
    alias: index2pMIHKjwaFMeta?.alias || [],
    redirect: index2pMIHKjwaFMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/emails/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Nx7XFUlujDMeta?.name ?? "managed-apps-id",
    path: _91id_93Nx7XFUlujDMeta?.path ?? "/managed-apps/:id()",
    meta: _91id_93Nx7XFUlujDMeta || {},
    alias: _91id_93Nx7XFUlujDMeta?.alias || [],
    redirect: _91id_93Nx7XFUlujDMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/managed-apps/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMrBGV59dCHMeta?.name ?? "managed-apps",
    path: indexMrBGV59dCHMeta?.path ?? "/managed-apps",
    meta: indexMrBGV59dCHMeta || {},
    alias: indexMrBGV59dCHMeta?.alias || [],
    redirect: indexMrBGV59dCHMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/managed-apps/index.vue").then(m => m.default || m)
  },
  {
    name: index6EF9PDELFWMeta?.name ?? "networks",
    path: index6EF9PDELFWMeta?.path ?? "/networks",
    meta: index6EF9PDELFWMeta || {},
    alias: index6EF9PDELFWMeta?.alias || [],
    redirect: index6EF9PDELFWMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/networks/index.vue").then(m => m.default || m)
  },
  {
    name: indexF4ab2vaKHRMeta?.name ?? "object-storages",
    path: indexF4ab2vaKHRMeta?.path ?? "/object-storages",
    meta: indexF4ab2vaKHRMeta || {},
    alias: indexF4ab2vaKHRMeta?.alias || [],
    redirect: indexF4ab2vaKHRMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/object-storages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93894SQ5F8pEMeta?.name ?? "services-id",
    path: _91id_93894SQ5F8pEMeta?.path ?? "/services/:id()",
    meta: _91id_93894SQ5F8pEMeta || {},
    alias: _91id_93894SQ5F8pEMeta?.alias || [],
    redirect: _91id_93894SQ5F8pEMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/services/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVBexc7dJR3Meta?.name ?? "services",
    path: indexVBexc7dJR3Meta?.path ?? "/services",
    meta: indexVBexc7dJR3Meta || {},
    alias: indexVBexc7dJR3Meta?.alias || [],
    redirect: indexVBexc7dJR3Meta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: indexTaQx1AveD8Meta?.name ?? "support",
    path: indexTaQx1AveD8Meta?.path ?? "/support",
    meta: indexTaQx1AveD8Meta || {},
    alias: indexTaQx1AveD8Meta?.alias || [],
    redirect: indexTaQx1AveD8Meta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QEWapsxfxmMeta?.name ?? "support-tickets-id",
    path: _91id_93QEWapsxfxmMeta?.path ?? "/support/tickets/:id()",
    meta: _91id_93QEWapsxfxmMeta || {},
    alias: _91id_93QEWapsxfxmMeta?.alias || [],
    redirect: _91id_93QEWapsxfxmMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/support/tickets/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlLlAHCRd7yMeta?.name ?? "traffic-bills-id-history",
    path: indexlLlAHCRd7yMeta?.path ?? "/traffic/bills/:id()/history",
    meta: indexlLlAHCRd7yMeta || {},
    alias: indexlLlAHCRd7yMeta?.alias || [],
    redirect: indexlLlAHCRd7yMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/traffic/bills/[id]/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexynxYGxH5p2Meta?.name ?? "traffic-bills-id",
    path: indexynxYGxH5p2Meta?.path ?? "/traffic/bills/:id()",
    meta: indexynxYGxH5p2Meta || {},
    alias: indexynxYGxH5p2Meta?.alias || [],
    redirect: indexynxYGxH5p2Meta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/traffic/bills/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxDLlDG7ZGMeta?.name ?? "traffic-bills",
    path: indexbxDLlDG7ZGMeta?.path ?? "/traffic/bills",
    meta: indexbxDLlDG7ZGMeta || {},
    alias: indexbxDLlDG7ZGMeta?.alias || [],
    redirect: indexbxDLlDG7ZGMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/traffic/bills/index.vue").then(m => m.default || m)
  },
  {
    name: api_45tokensFfRaQvBBZ2Meta?.name ?? "user-api-tokens",
    path: api_45tokensFfRaQvBBZ2Meta?.path ?? "/user/api-tokens",
    meta: api_45tokensFfRaQvBBZ2Meta || {},
    alias: api_45tokensFfRaQvBBZ2Meta?.alias || [],
    redirect: api_45tokensFfRaQvBBZ2Meta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/user/api-tokens.vue").then(m => m.default || m)
  },
  {
    name: data_45processing_45agreementEAvFfLIwQzMeta?.name ?? "user-data-processing-agreement",
    path: data_45processing_45agreementEAvFfLIwQzMeta?.path ?? "/user/data-processing-agreement",
    meta: data_45processing_45agreementEAvFfLIwQzMeta || {},
    alias: data_45processing_45agreementEAvFfLIwQzMeta?.alias || [],
    redirect: data_45processing_45agreementEAvFfLIwQzMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/user/data-processing-agreement.vue").then(m => m.default || m)
  },
  {
    name: indexggdRd11EUoMeta?.name ?? "user",
    path: indexggdRd11EUoMeta?.path ?? "/user",
    meta: indexggdRd11EUoMeta || {},
    alias: indexggdRd11EUoMeta?.alias || [],
    redirect: indexggdRd11EUoMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: login_45activitieshOa3AWD7IGMeta?.name ?? "user-login-activities",
    path: login_45activitieshOa3AWD7IGMeta?.path ?? "/user/login-activities",
    meta: login_45activitieshOa3AWD7IGMeta || {},
    alias: login_45activitieshOa3AWD7IGMeta?.alias || [],
    redirect: login_45activitieshOa3AWD7IGMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/user/login-activities.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BgzaxLIvgNMeta?.name ?? "webspaces-id",
    path: _91id_93BgzaxLIvgNMeta?.path ?? "/webspaces/:id()",
    meta: _91id_93BgzaxLIvgNMeta || {},
    alias: _91id_93BgzaxLIvgNMeta?.alias || [],
    redirect: _91id_93BgzaxLIvgNMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/webspaces/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvKUItpvWfLMeta?.name ?? "webspaces",
    path: indexvKUItpvWfLMeta?.path ?? "/webspaces",
    meta: indexvKUItpvWfLMeta || {},
    alias: indexvKUItpvWfLMeta?.alias || [],
    redirect: indexvKUItpvWfLMeta?.redirect || undefined,
    component: () => import("/builds/netzfabrik/apanel/backbone-api/app/pages/webspaces/index.vue").then(m => m.default || m)
  }
]