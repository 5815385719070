/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentMethod_invoice_read = {
    name?: PaymentMethod_invoice_read.name;
};

export namespace PaymentMethod_invoice_read {

    export enum name {
        SEPA_DEBIT = 'sepa_debit',
        CREDIT_CARD = 'credit_card',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
    }


}

