/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IpAddress_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read } from './IpAddress_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read';
import type { Region_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read } from './Region_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read';
import type { ServiceLevelAgreement_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read } from './ServiceLevelAgreement_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read';
import type { VirtualMachineTask_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read } from './VirtualMachineTask_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read';

export type VirtualMachine_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read = {
    readonly id?: string;
    tasks?: Array<VirtualMachineTask_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read>;
    readonly status?: VirtualMachine_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read.status;
    name?: string;
    cpu?: number;
    ramMb?: number;
    diskMb?: number;
    ipV4?: IpAddress_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read | null;
    ipV6?: IpAddress_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read | null;
    additionalIpV4?: Array<IpAddress_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read>;
    additionalIpV6?: Array<IpAddress_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read>;
    architecture?: VirtualMachine_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read.architecture;
    /**
     * UUID of the virtual machine
     */
    readonly vmUuid?: string | null;
    readonly serviceLevelAgreement?: ServiceLevelAgreement_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read | null;
    /**
     * Availability zone of the cloud server
     */
    readonly region?: Region_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read;
};

export namespace VirtualMachine_application_read_service_level_agreement_read_application_type_read_cloud_server_read_zone_read {

    export enum status {
        PENDING = 'pending',
        STARTING = 'starting',
        CREATING = 'creating',
        RUNNING = 'running',
        REBOOTING = 'rebooting',
        STOPPING = 'stopping',
        STOPPED = 'stopped',
        DELETING = 'deleting',
        SHUTTING_DOWN = 'shutting-down',
        DELETED = 'deleted',
    }

    export enum architecture {
        X86_64 = 'x86_64',
        AARCH64 = 'aarch64',
    }


}

